
import { ref } from "vue"
import ScoreAnalyse from "./components/ScoreAnalyse.vue"
import HeartRate from "./components/HeartRate.vue"
import BreathRate from "./components/BreathRate.vue"
import SleepCurve from "./components/SleepCurve.vue"
import SnoreLevel from "./components/SnoreLevel.vue"
import TurnOver from "./components/TurnOver.vue"
import Temperature from "./components/Temperature.vue"
import Humidity from "./components/Humidity.vue"
import Deduct from "./components/Deduct.vue"
import {
  format as scoreFormat,
  duration
} from "@/assets/script/utils/sleep-ace.util"
import {
  SleepDataVo,
  SleepAceService
} from "@/assets/script/service/sleep-ace.service"
import { Toast } from "vant"
import { Ref, UnwrapRef } from "@vue/reactivity"

interface Props {
  id: string
}

export default {
  props: ["id"],
  components: {
    ScoreAnalyse,
    HeartRate,
    BreathRate,
    TurnOver,
    SleepCurve,
    SnoreLevel,
    Temperature,
    Humidity,
    Deduct
    // DateType
  },
  setup: function(props: Props) {
    const emptyMessage = ref("")
    const item: Ref<UnwrapRef<SleepDataVo | undefined>> = ref(undefined)

    const getSleepData = async () => {
      const toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner"
      })

      const result = await SleepAceService.getSleepData(props.id)
      emptyMessage.value = result.message ?? ""
      toast.clear()
      item.value = result.data
    }
    getSleepData()

    return {
      emptyMessage,
      item,
      scoreFormat,
      duration
    }
  }
}
